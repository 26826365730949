import React, { useMemo, useCallback } from 'react';
import { Group } from '@visx/group';
import { scaleTime, scaleLinear } from '@visx/scale';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { LinePath, AreaClosed } from '@visx/shape';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';
import { bisector, extent, max, min } from 'd3-array';
import { timeFormat } from 'd3-time-format';
import { EventHistoryChartProps, EventData } from '../../types';
import _ from 'lodash';
import { localPoint } from '@visx/event';
import { NumberedCumWasteModelRow } from '../../domain/NumberedCumWasteModelRow';
import { GridRows, GridColumns } from '@visx/grid';

const margin = { top: 40, right: 30, bottom: 50, left: 60 };

const daycolors: string[] = [
  '#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b', '#e377c2',
];

const formatDate = timeFormat('%b %d, %Y');

const EventHistoryChart: React.FC<EventHistoryChartProps> = ({ data, width, height }) => {
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    showTooltip,
    hideTooltip,
  } = useTooltip<EventData>();

  const { TooltipInPortal } = useTooltipInPortal();

  const processedData = useMemo(() => {
    const grouped = _.groupBy(data, 'event_no');
    return Object.fromEntries(
      Object.entries(grouped).map(([key, events]) => {
        const sorted = _.sortBy(events, (d) => new Date(d.ts).getTime());
        let cumulativeWaste = 0;
        const processedEvents = sorted.map((event) => ({
          ...event,
          cum_waste: (cumulativeWaste += 24 * event.waste),
        }));
        return [parseInt(key, 10), processedEvents];
      })
    );
  }, [data]);

  const allDataPoints = useMemo(() => Object.values(processedData).flat(), [processedData]);

  const xExtent = extent(allDataPoints, (d) => new Date(d.ts)) as [Date, Date];
  const yMax = max(allDataPoints, (d) => d.cum_waste) || 0;
  const yMin = min(allDataPoints, (d) => d.cum_waste) || 0;

  const xScale = useMemo(
    () => scaleTime({
      domain: xExtent,
      range: [margin.left, width - margin.right],
    }),
    [width, xExtent]
  );

  const yScale = useMemo(
    () => scaleLinear({
      domain: [Math.min(yMin, 0), Math.max(yMax, 0)],
      range: [height - margin.bottom, margin.top],
      nice: true,
    }),
    [height, yMin, yMax]
  );

  const bisectDate = useMemo(() => bisector<EventData, Date>((d) => new Date(d.ts)).left, []);

  const handleTooltip = useCallback(
    (event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>) => {
      const { x } = localPoint(event) || { x: 0 };
      const x0 = xScale.invert(x - margin.left);
      const index = bisectDate(allDataPoints, x0, 1);
      const d0 = allDataPoints[index - 1];
      const d1 = allDataPoints[index];
      const d = d1 && x0 && (x0.valueOf() - new Date(d0.ts).getTime() > new Date(d1.ts).getTime() - x0.valueOf()) ? d1 : d0;
      showTooltip({
        tooltipData: d,
        tooltipLeft: x,
        tooltipTop: yScale(d.cum_waste || 0),
      });
    },
    [showTooltip, xScale, yScale, allDataPoints, bisectDate]
  );

  return (
    <div style={{ position: 'relative' }}>
      <svg width={width} height={height}>
        <Group>
          <rect width={width} height={height} fill="#ffffff" />
          <GridRows
            scale={yScale}
            width={width - margin.left - margin.right}
            height={height - margin.top - margin.bottom}
            left={margin.left}
            stroke="#e0e0e0"
            strokeOpacity={0.5}
            strokeDasharray="2,2"
          />
          <GridColumns
            scale={xScale}
            width={width - margin.left - margin.right}
            height={height - margin.top - margin.bottom}
            top={margin.top}
            stroke="#e0e0e0"
            strokeOpacity={0.5}
            strokeDasharray="2,2"
          />
          <AxisBottom
            top={height - margin.bottom}
            scale={xScale}
            numTicks={width > 520 ? 10 : 5}
          />
          <AxisLeft
            left={margin.left}
            scale={yScale}
            numTicks={5}
            label="Event Impact Cumulative (kWh)"
            labelProps={{
              fill: '#000',
              fontSize: 12,
              fontWeight: 'bold',
              textAnchor: 'middle',
              dy: '-2em',
            }}
          />
          {Object.entries(processedData).map(([event_no, events]) => {
            const color = daycolors[(1 + (parseInt(event_no, 10) % 7)) % daycolors.length];
            return (
              <Group key={`group-${event_no}`}>
               <AreaClosed
  data={events}
  x={(d) => xScale(new Date(d.ts)) || 0}
  y={(d) => yScale(d.cum_waste||0) || 0}
  y0={yScale(0)} // Setze die Baseline auf die x-Achse
  yScale={yScale}
  fill={color}
  fillOpacity={0.3}
  stroke={color}
/>

<LinePath
  data={events}
  x={(d) => xScale(new Date(d.ts)) || 0}
  y={(d) => yScale(d.cum_waste|| 0) || 0 }
  stroke={color}
  strokeWidth={2}
  curve={undefined}
/>

              </Group>
            );
          })}
          <rect
            x={margin.left}
            y={margin.top}
            width={width - margin.left - margin.right}
            height={height - margin.top - margin.bottom}
            fill="transparent"
            onTouchStart={handleTooltip}
            onTouchMove={handleTooltip}
            onMouseMove={handleTooltip}
            onMouseLeave={hideTooltip}
          />
        </Group>
      </svg>
      {tooltipData && (
        <TooltipInPortal
          key={Math.random()}
          top={tooltipTop}
          left={tooltipLeft}
          style={{
            color: 'white',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            borderRadius: '4px',
            fontSize: '12px',
            padding: '8px',
            transform: 'translate(-50%, -100%)',
          }}
        >
          <div><strong>Event:</strong> {`Event ${tooltipData.event_no}`}</div>
          <div><strong>Timestamp:</strong> {formatDate(new Date(tooltipData.ts))}</div>
          <div><strong>Cumulative Waste:</strong> {tooltipData.cum_waste?.toFixed(2)} kWh</div>
          <div><strong>Waste:</strong> {tooltipData.waste.toFixed(2)} kWh</div>
          {tooltipData.text && <div><strong>Info:</strong> {tooltipData.text}</div>}
        </TooltipInPortal>
      )}
    </div>
  );
};

export default EventHistoryChart;
