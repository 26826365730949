// src/App.tsx
import React, {useRef} from 'react';

import { useAuth } from "react-oidc-context";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faUser} from "@fortawesome/free-solid-svg-icons";
import MeterReadingsChart from "./components/charts/MeterReadingsChart";
import {Route, BrowserRouter, Routes, Navigate} from 'react-router-dom';
import { PrimeReactProvider } from 'primereact/api';
import {Menu} from "primereact/menu";
import {Welcome} from "./components/Welcome";
import ProfileImage from "./components/ProfileImage";
import StepComponent from './components/StepComponent';
import OverviewChart from "./components/charts/OverviewChart";
import CallbackHandler from "./components/OICDCallback";
import Dashboard from "./components/Dashboard";
import { Splitter, SplitterPanel } from 'primereact/splitter';
import { Tag } from 'primereact/tag';

        
import AppContent from './components/AppContent';

const App: React.FC = () => {
  const auth = useAuth();
  const menuLeft = useRef<Menu>(null);
   
 
  // Use data2 only if it's available
  
  const items = [
    {
      label: 'User',
      items: [
        {
          label: 'Manage Account',
          icon: 'pi pi-refresh'
        },
        {
          label: 'Logout',
          icon: 'pi pi-upload',
          command: () => {
            void auth.signoutRedirect();
          }
        }
      ]
    }
  ];
  switch (auth.activeNavigator) {
    case "signinSilent":
      return  <div className="App">
        <div className="content">Signing you in...</div></div>
        ;
    case "signoutRedirect":
      return  <div className="App">
        <div className="content">Signing you out...</div>
      </div>
  }

  if (auth.isLoading) {
    return <div className="App"><div className="content">Loading...</div></div>
  }

  if (auth.error) {
    return <div className="content">Oops... {auth.error.message}</div>;
  }
  const handleLogin = () => {
    console.log('###################Logging in');
    const currentUrl = window.location.pathname + window.location.search;
    console.log('###################Setting postLoginRedirect to', currentUrl);
    sessionStorage.setItem('postLoginRedirect', currentUrl);
    auth.signinRedirect();
  };
  let userplace
  if(auth.isAuthenticated){
    userplace= <span onClick={(event) => menuLeft?.current?.toggle(event)}><ProfileImage name={auth.user?.profile.name||'XX'} /></span>
  } else{
    userplace=<span className="icon"><FontAwesomeIcon icon={faUser} onClick={(event) =>handleLogin()}/>Sign In</span>
  }
  console.log(auth.user)
 
  

  return (

    <div className="App">
      <PrimeReactProvider>
        <div className="header">
          <Menu model={items} popup ref={menuLeft} id="popup_menu_left" />

          <div><img className="navbar-brand" src="/logo_small.svg"/><span className={'title'}></span> <span className="navbar-version">1.0.1</span></div>
          <div>
            
          </div>
        </div>
        <div className="content">
          <BrowserRouter>
            <Routes>
              <Route path="/loginCallback" element={<CallbackHandler/>}/>
              <Route path="/meterreadings/:meterKey" element={
                auth.isAuthenticated ? (
                  <MeterReadingsChart />
                ) : (
                  <Navigate to="/" replace />
                )
              } />
              <Route path="/overview/:namespace/:name" element={
                  <OverviewChart />
              } />
          
              <Route path="/demo/:namespace/:meterkey" element={
                <AppContent/>
               
            
              } 
              
              />
              <Route path="/dashboard/:namespace/:name" element={
                <Dashboard />
              } />
              <Route path="/wizard" element={<StepComponent />} />
              <Route path="/" element={<Welcome />} />

              {/* Other routes can also be defined here */}
            </Routes>
          </BrowserRouter>
        </div>
       
      </PrimeReactProvider>
    </div>
  );


}

export default App;