import React, { useMemo, useState, useRef, useCallback } from 'react';
import { scaleTime, scaleLinear, scaleOrdinal } from '@visx/scale';
import { Group } from '@visx/group';
import { AxisLeft, AxisBottom } from '@visx/axis';
import { LinePath, Line } from '@visx/shape';
import { extent, bisector } from 'd3-array';
import { NumberedModelRow } from '../../domain/NumberedModelRow';
import { useTooltip } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import Legend from '../Legend';
import { GridRows, GridColumns } from '@visx/grid';

const colors = ["#1B9E77", "#D95F02", "#7570B3", "#E7298A", "#66A61E", "#E6AB02", "#A6761D"];
const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

interface TimeChartProps {
  data: NumberedModelRow[];
  width: number;
  height: number;
}

const TimeChart: React.FC<TimeChartProps> = ({ data, width, height }) => {
  const margin = { top: 20, right: 20, bottom: 60, left: 40 };
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;

  const xScale = useMemo(
    () => scaleTime({
      range: [0, innerWidth],
      domain: extent(data, d => new Date(d.ts)) as [Date, Date],
    }),
    [data, innerWidth]
  );

  const yScale = useMemo(
    () => {
      const [min, max] = extent(data, d => d.rate) as [number, number];
      return scaleLinear({
        range: [innerHeight, 0],
        domain: [Math.floor(min), Math.ceil(max)],
        nice: true,
      });
    },
    [data, innerHeight]
  );

  const getYAxisTicks = useCallback(() => yScale.ticks().filter(Number.isInteger), [yScale]);
  const getXAxisTicks = useCallback(() => xScale.ticks(12), [xScale]);
  const getXAxisGridlines = useCallback(() => xScale.ticks(12), [xScale]);

  const groupedData = useMemo(() => {
    const groups: { [key: number]: NumberedModelRow[] } = {};
    for (let i = 1; i <= 7; i++) {
      groups[i] = data.filter(d => d.iso_dow === i);
    }
    return groups;
  }, [data]);

  const {
    showTooltip,
    hideTooltip,
    tooltipData,
    tooltipLeft,
    tooltipTop,
  } = useTooltip<NumberedModelRow>();

  const [hoveredPoint, setHoveredPoint] = useState<NumberedModelRow | null>(null);
  const [tooltipPos, setTooltipPos] = useState({ x: 0, y: 0 });
  const [hiddenWeekdays, setHiddenWeekdays] = useState<number[]>([]);

  const colorScale = useMemo(
    () => scaleOrdinal({
      domain: weekdays,
      range: colors,
    }),
    []
  );

  const bisectDate = useMemo(() => bisector<NumberedModelRow, Date>(d => new Date(d.ts)).left, []);

  const handleMouseMove = useCallback(
    (event: React.MouseEvent<SVGElement>) => {
      const { x, y } = localPoint(event) || { x: 0, y: 0 };
      const xDate = xScale.invert(x - margin.left);
      const index = bisectDate(data, xDate, 1);
      const d0 = data[index - 1];
      const d1 = data[index];
      const closestPoint = d1 && xDate.valueOf() - new Date(d0.ts).valueOf() > new Date(d1.ts).valueOf() - xDate.valueOf() ? d1 : d0;
      
      const tooltipX = x >= width / 2 ? x - 10 : x + 60;
      
      setHoveredPoint(closestPoint);
      setTooltipPos({ x: tooltipX, y });
      showTooltip({
        tooltipData: closestPoint,
        tooltipLeft: x,
        tooltipTop: y,
      });
    },
    [showTooltip, xScale, data, width, margin.left, bisectDate]
  );

  const handleMouseLeave = useCallback(() => {
    hideTooltip();
    setHoveredPoint(null);
    setTooltipPos({ x: 0, y: 0 });
  }, [hideTooltip]);

  const toggleWeekday = useCallback((weekday: string) => {
    const weekdayIndex = weekdays.indexOf(weekday) + 1;
    setHiddenWeekdays(prev =>
      prev.includes(weekdayIndex)
        ? prev.filter(day => day !== weekdayIndex)
        : [...prev, weekdayIndex]
    );
  }, []);

  const svgRef = useRef<SVGSVGElement>(null);

  return (
    <div style={{ position: 'relative' }}>
      <svg width={width} height={height} ref={svgRef}
           onMouseMove={handleMouseMove}
           onMouseLeave={handleMouseLeave}>
        <Group left={margin.left} top={margin.top}>
          <GridRows
            scale={yScale}
            width={innerWidth}
            height={innerHeight}
            stroke="#e0e0e0"
            strokeOpacity={0.5}
            tickValues={getYAxisTicks()}
          />
          <GridColumns
            scale={xScale}
            width={innerWidth}
            height={innerHeight}
            stroke="#e0e0e0"
            strokeOpacity={0.5}
            tickValues={getXAxisGridlines()}
          />

          {Object.entries(groupedData).map(([iso_dow, rows]) => {
            if (hiddenWeekdays.includes(Number(iso_dow))) return null;
            return (
              <LinePath
                key={iso_dow}
                data={rows}
                x={d => xScale(new Date(d.ts))}
                y={d => yScale(d.expected)}
                stroke={colorScale(weekdays[Number(iso_dow) - 1])}
                strokeWidth={1.5}
              />
            );
          })}

          {data.map((d, i) => {
            if (hiddenWeekdays.includes(d.iso_dow)) return null;
            return (
              <circle
                key={i}
                cx={xScale(new Date(d.ts))}
                cy={yScale(d.rate)}
                r={3}
                fill="none"
                stroke={colorScale(weekdays[d.iso_dow - 1])}
              />
            );
          })}

          <AxisBottom 
            top={innerHeight} 
            scale={xScale} 
            tickValues={getXAxisTicks()}
          />
          <AxisLeft
            scale={yScale}
            numTicks={6}
            tickFormat={(value) => `${Math.round(+value)}` }
            tickValues={getYAxisTicks()}
          />
          
          {tooltipData && tooltipLeft !== undefined && (
            <Line
              from={{ x: tooltipLeft - margin.left, y: 0 }}
              to={{ x: tooltipLeft - margin.left, y: innerHeight }}
              stroke="#999"
              strokeWidth={1}
              pointerEvents="none"
            />
          )}
        </Group>
        
        <text
          x={-height / 2}
          y={15}
          transform="rotate(-90)"
          textAnchor="middle"
          fontSize={12}
          fill="#000000"
        >
          Power (kW)
        </text>

        <g transform={`translate(${margin.left}, ${height - margin.bottom + 40})`}>
          <Legend
            days={weekdays}
            colors={colors}
            visibleDays={new Set(weekdays.map((_, i) => i + 1).filter(day => !hiddenWeekdays.includes(day)))}
            toggleDay={(day) => toggleWeekday(weekdays[day - 1])}
            width={innerWidth}
          />
        </g>
      </svg>
      
      {tooltipData && (
        <div
          style={{
            position: 'absolute',
            top: tooltipPos.y,
            left: tooltipPos.x,
            transform: `translate(${tooltipPos.x > width / 2 ? '-100%' : '0%'}, -50%)`,
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            padding: '8px',
            borderRadius: '4px',
            boxShadow: '0 1px 10px rgba(0,0,0,0.1)',
            pointerEvents: 'none',
            display: 'grid',
            gridTemplateColumns: 'auto auto',
            gap: '4px 8px',
          }}
        >
          <div style={{ textAlign: 'left' }}><strong>Date:</strong></div>
          <div style={{ textAlign: 'right' }}>{new Date(hoveredPoint?.ts || '').toLocaleDateString()}</div>
          <div style={{ textAlign: 'left' }}><strong>Weekday:</strong></div>
          <div style={{ textAlign: 'right' }}>{weekdays[(hoveredPoint?.iso_dow || 1) - 1]}</div>
          <div style={{ textAlign: 'left' }}><strong>Rate:</strong></div>
          <div style={{ textAlign: 'right' }}>{hoveredPoint?.rate.toFixed(2)}</div>
          <div style={{ textAlign: 'left' }}><strong>Expected:</strong></div>
          <div style={{ textAlign: 'right' }}>{hoveredPoint?.expected.toFixed(2)}</div>
        </div>
      )}
    </div>
  );
};

export default TimeChart;